







































































import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";

@Component
export default class EpisodeForm extends Vue {
  public episode = {
    id: 0,
    name: '',
    link: '',
    status: 1,
    episodeQuestions: []
  };
  public settings: Settings;
  public id: number;
  public tmpQuestion: '';

  public constructor() {
    super();
    this.settings = new Settings();
    this.id = +this.$route.params.id;
  }

  public addEpisode() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    if (this.episode.id > 0) {
      this.axios.put(
          this.settings.settings.host + '/api/episodes/' + this.episode.id,
          {
            name: this.episode.name,
            status: this.episode.status,
            link: this.episode.link
          },
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Pytanie zostało edytowane'
        });
      });
    } else {
      this.axios.post(
          this.settings.settings.host + '/api/episodes',
          {
            name: this.episode.name,
            status: this.episode.status,
            link: this.episode.link,
            episodeQuestions: this.episode.episodeQuestions
          },
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Odcinek został dodany'
        });
        that.$router.push('/panel/episode/list');
      });
    }
  }

  public addQuestion() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    if (this.episode.id > 0) {
      this.axios.post(
          this.settings.settings.host + '/api/episode-questions',
          {
            'question': this.tmpQuestion,
            'episode': this.episode['@id']
          },
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.episode.episodeQuestions.push({
          question: that.tmpQuestion
        });
        that.tmpQuestion = '';
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Pytanie zostało dodane'
        });
      })
    } else {
      this.episode.episodeQuestions.push({
        question: this.tmpQuestion
      });
      this.tmpQuestion = '';
      this.$notify({
        group: 'notify',
        type: 'success',
        text: 'Pytanie zostało dodane'
      });
    }
  }

  public loadData(): void {
    this.axios.get(this.settings.settings.host + '/api/episodes/' + this.$route.params.id, { //this.id.toString(),{
      params: {},
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.episode = response.data;
    });
  }

  public deleteQuestion(idx, questionId): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    if (this.episode.id > 0) {
      this.axios.delete(
          this.settings.settings.host + '/api/episode-questions/' + questionId,
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Pytanie zostało usunięte'
        });
      })
    }
    this.episode.episodeQuestions.splice(idx, 1);
  }

  created(): void {
    if (this.id > 0) {
      this.loadData();
    }
  }
}
